import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppService} from '../../services/app.service';
import {SettingsService} from '../../services/settings.service';
import {environment} from '../../../environments/environment';
import {CustomersModelService} from '../../models/customers.model.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private appService: AppService,
    private settingsService: SettingsService,
    private customersModelService: CustomersModelService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.baseUrl) >= 0) {

      if (this.appService.employeeToken) {
        request = request.clone({
          setHeaders: {
            employeeToken: this.appService.employeeToken
          } as any
        });
      }

      if (this.customersModelService.selectedCustomer?.id) {
        request = request.clone({
          setHeaders: {
            customerToken: this.customersModelService.selectedCustomer.id.toString()
          } as any
        });
      }
    }

    return next.handle(request);
  }

}
