import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, TimeoutError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, timeout} from 'rxjs/operators';
import {SettingsService} from '../../services/settings.service';
import {AppService} from '../../services/app.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private appService: AppService
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // this.appService.requestTimeoutExpended = false;
    return next.handle(request).pipe(
      timeout(120000),
      catchError(err => {
        if (err instanceof TimeoutError) {
          console.error('Timeout has occurred', request.url);
          // this.appService.requestTimeoutExpended = true;
        }
        return next.handle(request);
      })
    );
  }

}
