import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {NavController} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  router: Router;
  location: Location;
  navController: NavController;

  constructor(
    router: Router,
    location: Location,
    navController: NavController
  ) {
    this.router = router;
    this.location = location;
    this.navController = navController;
  }

  goTo(url: string, opt = {}, replaceUrl = false) {
    this.router.navigate([url], {queryParams: opt, replaceUrl});
  }

  // goToUrl(url: any) {
  //   if (typeof url === 'string' || url instanceof String) {
  //     url = [url];
  //   }
  //
  //   this.router.navigate(url);
  // }

  goBack() {
    this.navController.back();
  }
}
